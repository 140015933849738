var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Mortality Entry ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"action":"apps-purchase","method":"post"},on:{"submit":_vm.addMortality}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Entry Date","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.FormData.entry_date),callback:function ($$v) {_vm.$set(_vm.FormData, "entry_date", $$v)},expression:"FormData.entry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" No. of Birds Sick"),_c('span',{staticClass:"text-danger"})]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"No. of Birds Sick","rules":{
										max_value: this.flockData.alive_birds,
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"No. of Birds Sick","type":"text","name":"sick_birds_quantity"},model:{value:(_vm.FormData.sick_birds_quantity),callback:function ($$v) {_vm.$set(_vm.FormData, "sick_birds_quantity", $$v)},expression:"FormData.sick_birds_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" No. of Birds Dead"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"No. of Birds Dead","rules":{
										required: true,
										max_value: this.flockData.alive_birds - _vm.FormData.sick_birds_quantity,
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"No. of Birds Dead","type":"text","name":"dead_birds_quantity"},on:{"input":function($event){return _vm.calculateMortality(_vm.FormData.dead_birds_quantity)}},model:{value:(_vm.FormData.dead_birds_quantity),callback:function ($$v) {_vm.$set(_vm.FormData, "dead_birds_quantity", $$v)},expression:"FormData.dead_birds_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Morbidity Rate % "),_c('span',{staticClass:"text-danger"})]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Morbidity Rate % "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Morbidity Rate %","type":"text","name":"morbidity_rate"},model:{value:(_vm.FormData.morbidity_rate),callback:function ($$v) {_vm.$set(_vm.FormData, "morbidity_rate", $$v)},expression:"FormData.morbidity_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mortality Rate % "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"mortality rate %","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"mortality rate %","type":"text","name":"mortality_rate","disabled":""},model:{value:(_vm.mortality),callback:function ($$v) {_vm.mortality=$$v},expression:"mortality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Remarks")]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Remarks","type":"text","name":"remarks"},model:{value:(_vm.FormData.remarks),callback:function ($$v) {_vm.$set(_vm.FormData, "remarks", $$v)},expression:"FormData.remarks"}})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }
<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Mortality Entry
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-purchase" method="post" @submit="addMortality">
				<b-card>
					<!-- <div class="d-flex justify-content-end mb-0 mt-2 mr-2">
						<b-button variant="primary"> Total Alive Birds : {{ this.total_chick ? total_chick : 0 }} </b-button>
					</div> -->
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Date <span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Entry Date" :rules="{ required: true }">
									<b-form-datepicker
										v-model="FormData.entry_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> No. of Birds Sick<span class="text-danger"></span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="No. of Birds Sick"
										:rules="{
											max_value: this.flockData.alive_birds,
										}"
									>
										<b-form-input
											v-model="FormData.sick_birds_quantity"
											placeholder="No. of Birds Sick"
											type="text"
											name="sick_birds_quantity"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> No. of Birds Dead<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="No. of Birds Dead"
										:rules="{
											required: true,
											max_value: this.flockData.alive_birds - FormData.sick_birds_quantity,
										}"
									>
										<b-form-input
											v-model="FormData.dead_birds_quantity"
											placeholder="No. of Birds Dead"
											type="text"
											name="dead_birds_quantity"
											@input="calculateMortality(FormData.dead_birds_quantity)"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Morbidity Rate % <span class="text-danger"></span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Morbidity Rate % ">
										<b-form-input
											v-model="FormData.morbidity_rate"
											placeholder="Morbidity Rate %"
											type="text"
											name="morbidity_rate"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Mortality Rate % <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="mortality rate %" rules="required">
										<b-form-input
											v-model="mortality"
											placeholder="mortality rate %"
											type="text"
											name="mortality_rate"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Remarks</template>
								<div class="form-label-group">
									<b-form-input v-model="FormData.remarks" placeholder="Remarks" type="text" name="remarks" />
								</div>
							</b-form-group>
						</b-col>
						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selected: null,
			FormData: {
				created_at: null,
				sick_birds_quantity: null,
				dead_birds_quantity: null,
				morbidity_rate: null,
				mortality_rate: null,
				remarks: null,
			},
			flockData: [],
			mortality: null,
		};
	},
	created: function () {
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		this.getFlockDetails();
	},
	methods: {
		goBack() {
			this.$router.push(`/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`);
		},
		getFlockDetails() {
			axiosIns
				.get(`web/flock-details/${this.flockId}`)
				.then((response) => {
					this.flockData = response.data.flock_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addMortality(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("sick_birds_quantity", this.FormData.sick_birds_quantity);
					data.append("dead_birds_quantity", this.FormData.dead_birds_quantity);
					data.append("morbidity_rate", this.FormData.morbidity_rate);
					data.append("mortality_rate", this.mortality);
					data.append("remarks", this.FormData.remarks);
					data.append("entry_date", this.FormData.entry_date);
					const farm_id = this.$route.params.farmId;
					axiosIns
						// .post(`web/farm/${farm_id}/purchaseVaccine`, data)
						.post(`web/farm/${this.farmId}/shed/${this.shedId}/flock/${this.flockId}/morbidity-entry`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								path: `/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
		calculateMortality(birds_dead) {
			this.mortality = (birds_dead / this.flockData.alive_birds) * 100;
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
